﻿@charset "utf-8";
/* CSS Document */

@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
/*==================================================================================================================
■リセット
==================================================================================================================*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
th,
td,
header,
article,
aside,
nav,
footer,
hgroup,
section,
button {
	margin: 0;
	padding: 0;
}
fieldset,
img,
iframe {
	border: 0;
	vertical-align: top;
}
select,
input,
textarea,
button:not(.react-datepicker__navigation) {
	min-height: 36px;
	padding: 0.25em;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 100%;
	font-weight: 400;
	font-family: "Noto Sans JP";
	line-height: 1;
	vertical-align: baseline;
	box-sizing: border-box;
	color: #666;
}
select:focus,
input:focus,
textarea:focus,
button:focus {
	outline: 0;
	border: 1px solid #aaa;
}
input[type="checkbox"], input[type="radio"] {
	min-height: auto;
}
input[type="submit"] {
	padding: 0.25em 0.5em;
	background: #fff;
	color: #17d;
	font-size: 80%;
}
input::placeholder {
	color: #d3d3d3;
}
button {
	padding: 0.25em 0.5em;
	background: #fff;
}
button:disabled {
	filter: grayscale(100%) contrast(75%);
}

address,
strong,
caption,
cite,
code,
dfn,
em,
th,
var {
	font-style: normal;
	font-weight: normal;
}
ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
caption,
th {
	text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}
q::before,
q::after {
	content: "";
}
abbr,
acronym,
iframe {
	border: 0;
}

.clear::after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

html,
body {
	font-family: "Noto Sans JP";
	font-weight: 400;
	font-size: 16px;
}
body {
	position: relative;
}
body.development {
	background-color: rgba(255, 0, 0, 0.1);
}
body.development #main-header a.logo::after {
	content: "SASAGASE(開発環境)";
}
div.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    font-weight: 600;
}
/*==================================================================================================================
■共通class
==================================================================================================================*/
.flex-box {
	display: flex;
}
.flex-box.between {
	justify-content: space-between;
}
.flex-box.end {
	justify-content: flex-end;
}
.flex-box.wrap {
	flex-wrap: wrap;
}
.flex-box.column {
	flex-direction: column;
}

.baseposition {
	position: relative;
}

@keyframes show {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes hide {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
/*==================================================================================================================
■ヘッダー・メイン・フッター
==================================================================================================================*/
#contents-wrapper {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
}
/*header設定*/
#main-header {
	display: block;
	padding: 0 0 25px 0;
	margin: 0;
	flex-shrink: 0;
}

/*footer設定*/
#main-footer {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	background: #1b3249;
	color: #eee;
	flex-shrink: 0;
}

/*mainコンテンツ設定*/
#main-container {
	position: relative;
	display: flex;
	flex-grow: 2;
}
#main-container > div.flex-box {
	width: 100%;
	overflow: auto;
}
#main-container > div.flex-box > div.wrapper-left {
	width: 220px;
	max-width: 300px;
	padding: 0;
	flex-shrink: 0;
	box-sizing: border-box;
}
#main-container > div.flex-box > div.wrapper-left::-webkit-scrollbar {
	width: 5px;
}

#main-container > div.flex-box > div.wrapper-right {
	width: 100%;
	height: 100%;
	padding: 0 0 25px 25px;
	box-sizing: border-box;
}
#main-container > div.flex-box > div.wrapper-left + div.wrapper-right {
	width: calc(100% - 220px);
}

#main-container > div.flex-box > div.wrapper-left nav,
#main-container > div.flex-box > div.wrapper-right article {
	margin: 0 0 0 0;
	padding: 1em;
	font-weight: 400;
	color: #444;
}
#main-container > div.flex-box > div.wrapper-right > h2 {
	margin: 0 0 0.5em 0;
	font-size: 21px;
	font-weight: 600;
}
article h3 {
	margin: 0 0 0.5em 0;
	font-size: 18px;
	font-weight: 600;
}
article h4,
article h5,
article h6 {
	margin: 0 0 0.5em 0;
	font-size: 16px;
	font-weight: 500;
}
article em {
	font-weight: bold;
}
div.flex-box > div.wrapper-right > article {
	margin: 0 0 25px 0;
}
#main-container > div.flex-box > div.wrapper-right > article,
#main-container div.wrapper-right > div.listings-contents > article {
	margin: 0 0 25px 0;
	padding: 25px;
	background-color: #fff;
	border-radius: 8px 0 0 8px;
}
#main-container > div.flex-box > div.wrapper-right > article:last-child,
#main-container div.wrapper-right > div.listings-contents > article:last-child {
	margin-bottom: 0;
}
#main-container > div.flex-box > div.wrapper-right article section {
	margin-bottom: 2.1em;
	background-color: inherit;
}
#main-container > div.flex-box > div.wrapper-right article section table {
	background-color: inherit;
}
#main-container > div.flex-box > div.wrapper-right article section:last-child {
	margin-bottom: 0;
}

/*入力テーブル*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
table.data-table {
	width: 100%;
}
table.data-table > tbody > tr > th {
	width: 200px;
	padding: 0.5em;
	vertical-align: top;
	font-size: 16px;
	font-weight: 500;
}
table.data-table > tbody > tr > td {
	padding: 0.5em;
}

ul.data-box {
	display: flex;
	width: 100%;
	max-width: 800px;
	margin: 0 0 -1em 0;
	flex-wrap: wrap;
	justify-content: space-between;
}
ul.data-box li {
	width: 40%;
	padding: 0;
	margin: 0 0 1em 0;
}
ul.data-box.edit-item-size_wrap {
	justify-content: flex-start;
}
ul.data-box.edit-item-size_wrap li {
	width: auto;
	margin: 0 1em 1em 0;
}
ul.data-box li input {
	width: 100%;
}

/*可変テーブル*/
div.edit-table-box {
	width: 100%;
	height: max-content;
	max-height: calc(90vh - 260px);
	padding-bottom: 8px;
	margin: 1em 0;
	overflow: auto;
}
table.edit-table {
	width: 100%;
	margin: 0;
}
table.edit-table,
table.edit-table th,
table.edit-table td {
	position: relative;
	table-layout: fixed;
	text-overflow: ellipsis;
	box-sizing: border-box;
	font-weight: 500;
	border-collapse: separate;
}
table.edit-table th {
	position: sticky;
	top: -1px;
	min-width: 40px;
	padding: 1em 0.5em;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	background-color: #f0f0f0;
	user-select: none;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	z-index: 90;
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
table.edit-table th.text-wrap,
table.edit-table td.text-wrap {
	white-space: normal;
}
table.edit-table th div.resizable-handle {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 5px;
	height: 100%;
	cursor: col-resize;
}
table.edit-table td {
	min-width: 40px;
	padding: 0;
	border-right: 1px solid #eee;
	border-bottom: 1px solid #eee;
	font-size: 14px;
	line-height: 1;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
table.edit-table table tr:first-child td {
	border-top: 1px solid #eee;
}
table.edit-table tr:nth-child(odd) td {
	background-color: #fee;
}
table.edit-table tr:nth-child(even) td {
	background-color: #fff;
}
table.edit-table tr.incorrect:nth-child(odd) td {
	background-color: inherit;
}
table.edit-table tr.incorrect:nth-child(even) td {
	background-color: inherit;
}
table.edit-table tr.set-item td {
	background-color: #E0E0FF;
}
table.edit-table th[width="10"],
table.edit-table td[width="10"] {
	width: auto;
}
table.edit-table td.center {
	text-align: center;
}
table.edit-table td.right {
	text-align: right;
}
table.edit-table th.noresize,
table.edit-table td.noresize {
	text-align: center;
}
table.edit-table th.noresize:first-child {
    position: sticky;
    left: 0;
    z-index: 1001;
}
table.edit-table td.noresize:first-child {
    position: sticky;
    left: 0;
    z-index: 1000;
}
table.edit-table .incorrect td.noresize:first-child {
	background: inherit;
}
table.edit-table th:last-child,
table.edit-table td:last-child {
	border-right: none;
}
.ui-resizable-e {
	width: 5px;
	right: 0;
}

table.edit-table th.disabled,
table.edit-table td.disabled {
	background-color: #fafafa;
}

table.edit-table th span {
	white-space: nowrap;
}
table.edit-table th i {
	cursor: pointer;
}
table.edit-table td span {
	display: inline-block;
	padding: 0.75em;
	letter-spacing: -0.025em;
	font-size: 14px;
	word-break: break-all;
}
table.edit-table td.data span,
table.edit-table td a {
	color: #17d;
	cursor: pointer;
	text-decoration: none;
}
table.edit-table td > select,
table.edit-table td > textarea,
table.edit-table td > input {
	border: 1px solid transparent;
	border-radius: 0;
	width: 100%;

	&.right {
		text-align: right;
	}
}
table.edit-table td > textarea {
	resize: vertical;
}
table.edit-table td > input[type="checkbox"] {
	width: min-content;
	margin: 0 auto;
	display: block;
}
table.edit-table td > select:active,
table.edit-table td > select:hover,
table.edit-table td > input:active,
table.edit-table td > input:hover {
	border-color: #999;
}
table.edit-table td > button.plus-button,
table.edit-table td > button.delete-button {
	min-height: auto;
	padding: 0;
	height: 1rem;
	width: 1rem;
	border-color: #999;
}
table.edit-table td > button.plus-button::after {
	content: "➕";
}
table.edit-table td > button.delete-button::after {
	content: "➖";
}
table.edit-table td > .edit-select {
	cursor: pointer;
	width: 100%;
	height: 100%;
	padding: 0.25em;
}

@media only screen and (max-height: 600px) {
	div.edit-table-box {
		max-height: max-content;
	}
}

table.edit-table.sub-edit-table {
	width: auto;

	input[type="text"] {
		width: 10em;
		border: 1px solid #ddd;
	}

	th.group-name {
		text-align: left;
		padding-left: 1em;
	}
	th.location-name {
		text-align: left;
		padding-left: 2em;
	}

	p.error {
		padding: 0.5em;
		color: #d00;
	}
}

.width50 {
	width: 50px;
}
.width100 {
	width: 100px;
}
.width200 {
	width: 200px;
}
.width300 {
	width: 300px;
}
.width400 {
	width: 400px;
}
.width500 {
	width: 500px;
}
.width600 {
	width: 600px;
}
.width700 {
	width: 700px;
}
.min-width10 {
	min-width: 10px;
}
.min-width50 {
	min-width: 50px;
}
.min-width100 {
	min-width: 100px;
}
.min-width200 {
	min-width: 200px;
}
.min-width300 {
	min-width: 300px;
}
.min-width400 {
	min-width: 400px;
}
.min-width500 {
	min-width: 500px;
}
.min-width600 {
	min-width: 600px;
}
.min-width700 {
	min-width: 700px;
}

/*required*/
table.data-table tr.required th::before,
article h2.required::before,
article h3.required::before,
article h4.required::before,
article h5.required::before,
article h6.required::before {
	content: "*";
	color: #c00;
}
table.data-table tr.commend th::before,
article h2.commend::before,
article h3.commend::before,
article h4.commend::before,
article h5.commend::before,
article h5.commend::before {
	content: "*";
	color: #666;
}

/*p span*/
article p {
	margin: 0 0 1.6em 0;
}
p.supplement {
	display: block;
	margin: 0.3em 0;
	font-size: 13px;
	color: #888;
}
article span.inline {
	display: inline;
}
p.supplement + table,
p.supplement + ul {
	margin-top: 1em;
}
article h3 + p.supplement,
article h4 + p.supplement,
article h5 + p.supplement,
article h6 + p.supplement {
	margin-top: -0.5em;
}
p.supplement + h3,
p.supplement + h4,
p.supplement + h5,
p.supplement + h6 {
	margin-top: 0.75em;
}
p.supplement + p.supplement {
	margin-top: -0.6em;
}

.point span,
span.point,
span.modaltoggle,
span.onetoggle {
	margin: 0;
	padding: 0;
	color: #0654ba;
	cursor: pointer;
	font-size: 18px;
}

.mb1 span,
span.mb1 {
	margin-bottom: 1em;
}

.fs12 span,
span.fs12 {
	font-size: 12px;
}
.fs14 span,
span.fs14 {
	font-size: 14px;
}
.fs16 span,
span.fs16 {
	font-size: 16px;
}
.fs18 span,
span.fs18 {
	font-size: 18px;
}
.fs20 span,
span.fs20 {
	font-size: 20px;
}

/*list*/
article ul {
	margin: 0 0 1.6em 0;
}
article ul li {
	margin: 0 0 1.6em 0;
}
article ul.child-mb05 li {
	margin: 0 0 0.5em 0;
}
article ul.child-mb1 li {
	margin: 0 0 1em 0;
}
article ul.mb0 {
	margin-bottom: 0;
}
article ul.mb05 {
	margin-bottom: 0.5em;
}
article ul.mb1 {
	margin-bottom: 1em;
}

article ul:last-child {
	margin-bottom: 0;
}

article ul.inline-blok li {
	display: inline-block;
	margin: 0 1em 0.5em 0;
}
article ul.inline-blok li.block,
article ul li.block {
	display: block;
}

article ul.onetoggle,
article ul.toggle {
	display: none;
	margin: 0.5em 0 0 0;
}

.edit-item-makertag-list,
.edit-item-itemtag-list {
	padding-left: 0.5em;
	margin: 0 0 0.5em 0;
}
.edit-item-makertag-list li,
.edit-item-itemtag-list li {
	user-select: none;
}

li.edit-item-makertag_wrap,
li.edit-item-itemtag_wrap,
li.edit-item-category_wrap {
	display: none;
	opacity: 0;
}

li.size-packing ul {
	margin-bottom: 1.6em;
}

/*input*/
input {
	display: inline-block;
}
table.data-table input[type="text"] {
	width: 100%;
}
.edit-sku-number_wrap input[type="text"],
.edit-item-number_wrap input[type="text"] {
	max-width: 200px;
}
.edit-item-jancode_wrap input[type="text"] {
	max-width: 300px;
}
.edit-item-name_wrap input[type="text"] {
	/* max-width: 500px; */
}
.edit-item-price_wrap input[type="text"],
.edit-item-size_wrap input[type="text"] {
	max-width: 100px;
}
input.tag-plus {
	max-width: 200px;
	margin-right: 10px;
}

textarea.public-key {
	width: 80em;
	height: 8em;
	font-family: Menlo, Monaco,'Courier New', Consolas, monospace;
	font-size: small;
}

table.data-table button:not(.react-datepicker__navigation) {
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: all 0.5s;
}

div.submit-area {
	padding: 0 0 0 1em;
	margin: 1em 0;
}
div.submit-area input,
div.submit-area button {
	display: inline-block;
	padding: 0.75em 1.6em;
	margin: 0 1em 0 0;
	font-size: 18px;
	border-radius: 5px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: all 0.5s;
}
div.submit-area input.register,
div.submit-area button.register {
	background-color: #17d;
	color: #fff;
	border: none;
}
div.submit-area input.save {
	background-color: #fff;
	color: #0654ba;
	border: none;
}
div.submit-area input.register:hover,
div.submit-area button.register:hover,
div.submit-area input.save:hover {
	background-color: #1b3249;
	color: #fff;
}
input.edit-sku-number + span {
	height: 36px;
	margin: 0 0 0 0.5em;
	line-height: 36px;
	vertical-align: middle;
	color: #6d3;
}

div.datepicker {
	display: inline-block;
}

/*textarea*/
textarea {
	width: 100%;
}
textarea.edit-bulk-id {
	display: block;
	max-width: 200px;
	margin: 0 0 0.5em 0;
}

/*form*/
form.formbox div.flex-box > div {
	margin: 0 10px 10px 0;
}
/*form.formbox div.flex-box div.onecolumn{
	width: 100%;
	flex-shrink: 0;
}*/
form.formbox div.size10rem {
	width: 10rem;
}
form.formbox div.size11rem {
	width: 11rem;
}
form.formbox div.size12rem {
	width: 12rem;
}
form.formbox div.size13rem {
	width: 13rem;
}
form.formbox div.size14rem {
	width: 14rem;
}
form.formbox div.size15rem {
	width: 15rem;
}
form.formbox div.size25rem {
	width: 25rem;
}
form.formbox div.submitArea {
	margin: 0;
}
form.formbox div.inputArea input,
form.formbox div.inputArea button {
	width: 100%;
}
form.formbox div.inputArea td {
	padding: 2px;
}
form.formbox div.inputArea td button {
	width: auto;
}
form.formbox div.inputArea td button,
form.formbox div.inputArea td input,
form.formbox div.inputArea td select {
	margin: 0 10px 0 0;
}
div.submitArea {
	margin: 1em 0;
}
div.submitArea input,
div.submitArea button {
	margin: 0 10px 0 0;
}
form.formbox button {
	position: relative;
	text-align: left;
}
form.formbox button.toggle::after {
	content: "\f0d7";
	font-family: FontAwesome;
	letter-spacing: 0;
	margin-right: 0.25em;
	float: right;
}
form.formbox button.toggle.open {
	border: 1px solid #aaa;
}
form.formbox ul.toggle {
	position: absolute;
	left: 0;
	top: 37px;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #fff;
	border: 1px solid #aaa;
	border-radius: 5px;
	box-sizing: border-box;
	z-index: 100;
}
form.formbox ul.toggle li {
	margin: 0;
	border-bottom: 1px dotted #eee;
	font-size: 14px;
}
form.formbox ul.toggle li:last-child {
	border: none;
}
form.formbox ul.toggle li span,
form.formbox ul.toggle li label {
	display: block;
	padding: 5px;
	cursor: pointer;
	color: #666;
}
form.formbox ul.toggle li label:hover {
	background-color: #17d;
	color: #fff;
}
form.formbox ul.toggle li input {
	width: 100%;
	border: 1px solid #eee;
}
form.formbox ul.toggle li label input {
	display: none;
	width: 1em;
	min-height: auto;
}
form.formbox div.item-tag > div.level-2,
form.formbox div.item-tag > div.level-3,
form.formbox div.item-tag > div.level-4 {
	display: none;
}

.bl_password {
	position: relative;

	input {
		padding-right: 40px;
	}

	.el_showPassword {
		&::before {
			content: "";
			width: 16px;
			height: 16px;
			background-image: url(../img/eye-slash-fill.svg);
			background-repeat: no-repeat;
			background-size: 100%;
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			right: 16px;
		}

		&.active::before {
			background-image: url(../img/eye-fill.svg);
		}
	}
}

p.error {
	font-size: 13px;
	margin: 4px 0;
	color: #d00;
}

/*div wrapper*/
div.modalbox {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: max-content;
	height: max-content;
	margin: auto;
	box-sizing: border-box;
	border-radius: 5px;
	background: #fff;
	box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
	z-index: 2147483641;
}
div.modalbox > div {
	width: max-content;
	height: max-content;
	max-width: 90vw;
	max-height: 90vh;
	padding: 35px;
	box-sizing: border-box;
	overflow: auto;
}
div.modalbox div.wrapper {
	margin: 0 0 2.4em 0;
}
div.modalbox div.wrapper:last-child {
	margin: 0;
}
div.modalbox input[type="submit"] {
	position: initial;
	width: auto;
	height: auto;
}

div.tagbox {
	margin: 0 0 0 0;
	padding: 0 0 0.5em 0;
}
div.tagbox input {
	margin: 0 3px 5px 0;
}
div.tagbox input.on {
	background-color: #333;
	color: #fff;
}

article div.onetoggle,
article div.toggle {
	display: none;
}

div.sizebox {
	margin: 0;
}
div.sizebox input {
	margin: 0 3px 5px 0;
}
div.sizebox input.size-plus {
	margin-right: 10px;
}
div.sizebox input.size-00 {
	background-color: #333;
	color: #fff;
}
div.sizebox input.on {
	background-color: #333;
	color: #fff;
}

div.expectation-taggroup {
	display: none;
	margin-top: 1.6em;
}
div.expectation-taggroup.visible {
	display: block;
}

/*listings-wrapper*/
div.listings-wrapper {
	display: flex;
	width: 100%;
	min-height: 300px;
	border: 1px solid #ddd;
	overflow: hidden;
}
div.listings-wrapper div.wrapper-left {
	width: 50%;
	height: 100%;
	flex-shrink: 0;
	box-sizing: border-box;
}
div.listings-wrapper div.wrapper-right {
	width: 50%;
	height: 100%;
	padding-left: 3px;
	background: #f5f5f5;
	border-left: 1px solid #ddd;
	box-sizing: border-box;
}
div.listings-contents {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: auto;
}
div.listings-wrapper div.wrapper-right div.listings-contents {
	padding: 1em 0 1em 1em;
	background: #f5f5f5;
}

/*tag-selector*/
div.tag-selector {
	display: flex;
	border: 2px solid #ddd;
	background: #eee;
	align-content: stretch;
}
div.tag-selector div.wrapper-left {
	min-width: 220px;
	border-right: 2px solid #fff;
	background: #fff;
}
div.tag-selector div.wrapper-center {
	display: flex;
	width: 50px;
	margin-left: 3px;
	border-right: 1px solid #eee;
	flex-direction: column;
	justify-content: center;
	background: #fff;
}
div.tag-selector div.wrapper-right {
	display: flex;
	max-width: 100%;
	flex-direction: column;
	flex-grow: 1;
	align-content: stretch;
	background: #fff;
}
div.tag-selector div.wrapper-right div.selected {
	padding: 0.5em;
	border-bottom: 1px solid #eee;
	flex-grow: 2;
}
div.tag-selector div.wrapper-right div.recommend {
	padding: 0.5em;
	flex-grow: 1;
}
div.tag-selector div.tag-tree {
	height: 100%;
}
div.tag-select {
}

div.selected ul {
	display: flex;
}
div.selected ul li {
	margin: 0;
	display: flex;
}
div.selected ul li span {
	display: inline-block;
	padding: 0.5em;
	margin: 0 5px 5px 0;
	border: 1px solid #eee;
	border-radius: 5px;
	background: #f5f5f5;
}

/*scrollbox*/
div.scrollbox {
	width: 100%;
	height: 0;
	padding-left: 25px;
	padding-right: 5px;
	overflow-y: auto;
	box-sizing: border-box;
}

/*leftnav*/
div.leftnav ul {
	padding-bottom: 0.6em;
	border-bottom: 1px solid #ddd;
}
div.leftnav ul li {
	margin: 0 0 1em 0;
	font-size: 14px;
}

/*tag-tree*/
div.tag-selector div.tag-tree {
	padding: 0;
}
ul.tag-tree.edit-on li {
	position: relative;
	padding-left: 1.5em;
	padding-right: 1.5em;
}
ul.tag-tree.edit-on li::after {
	position: absolute;
	top: 0;
	right: 0.5em;
	bottom: 0;
	height: 1em;
	margin: auto;
	content: "\f0c9";
	font-family: FontAwesome;
	letter-spacing: 0;
	text-indent: 0;
}
span.arrow::after {
	content: "\f0da";
	font-family: FontAwesome;
	margin-right: 0.25em;
}
span.arrow.open::after {
	content: "\f0d7";
	font-family: FontAwesome;
	margin-right: 0.25em;
}
ul.edit-on span.delete::after {
	position: absolute;
	top: 0;
	left: 0.5em;
	bottom: 0;
	height: 1em;
	margin: auto;
	content: "\f056";
	font-family: FontAwesome;
	letter-spacing: 0;
	color: #c00;
	text-indent: 0;
}
div.tag-tree div.edit {
	padding: 0.5em;
}
div.tag-tree div.edit ul.edit-button span {
	display: inline-block;
	width: 30px;
	height: 30px;
	overflow: hidden;
	border: 1px solid #999;
	background: #eee;
	border-radius: 3px;
	text-align: center;
	line-height: 30px;
}
div.tag-tree #tag-registration {
	width: 100%;
}
div.tag-tree select.tag-domain {
	width: 100%;
	border: none;
	background: transparent;
}
div.tag-tree select.tag-domain::before {
	display: block;
	width: 2px;
	height: 100%;
}
ul.tag-tree {
	width: 100%;
	flex-grow: 1;
	flex-basis: auto;
	flex-direction: column;
	justify-content: center;
}
ul.tag-tree li {
	display: block;
	width: 100%;
	margin: 0;
	padding: 0.5em;
	border-bottom: 1px solid #eee;
	box-sizing: border-box;
	font-size: 14px;
	color: #444;
}
ul.tag-tree li.tag-domain {
	border-top: 1px solid #eee;
}
ul.tag-tree li.into-top {
	border-top: 2px solid #ff0000;
	padding-top: 5px;
}
ul.tag-tree li.into-bottom {
	border-bottom: 2px solid #ff0000;
	padding-bottom: 6px;
}
ul.tag-tree li.into-child {
	border: 2px solid #ff0000;
	padding-top: 5px;
	padding-bottom: 6px;
}
ul.tag-tree li.into-child.tag-domain {
	padding-top: 6px;
}
ul.tag-tree li.into-top.tag-domain {
	padding-top: 6px;
}

/*写真登録部分*/
ul.photo-register-box {
	display: flex;
	width: 100%;
	margin: 0 0 1em 0;
	border-left: 1px solid #eee;
	flex-wrap: wrap;
}
ul.photo-register-box li {
	position: relative;
	width: 10%;
	height: 0;
	padding-top: 10%;
	margin: 0 0 -1px 0;
	border-top: 1px solid #eee;
	border-right: 1px solid #eee;
	border-bottom: 1px solid #eee;
	box-sizing: border-box;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}
ul.photo-register-box li > input[type="submit"] {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: auto;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	background: #fff;
	border: none;
	font-size: 30px;
	color: #ccc;
	border-radius: 0;
}
ul.photo-register-box li > input[type="submit"]:hover {
	background: #f5f5f5;
	color: #333;
}
ul.photo-register-box li > input[value="変更"] {
	background: transparent;
	color: transparent;
}
ul.photo-register-box li > input[value="変更"]:hover {
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
	font-size: 20px;
}

ul.photo-register-box.instructions-photo li {
	margin-bottom: 45px;
}
ul.photo-register-box.instructions-photo input[type="submit"] {
}
ul.photo-register-box.instructions-photo select {
	width: 100%;
	margin-top: 3px;
}

ul.photo-register-url {
	display: flex;
	margin-top: 0.5em;
	flex-wrap: wrap;
	justify-content: space-between;
}
ul.photo-register-url li {
	position: relative;
	width: 49%;
	height: 60px;
	padding: 0 0 0 110px;
	margin: 0 0 1em 0;
	box-sizing: border-box;
	font-size: 14px;
	color: #777;
	line-height: 1;
}
ul.photo-register-url li.add {
	padding: 0;
}
ul.photo-register-url input {
	display: block;
	width: 100%;
	margin-top: 0.5em;
}
ul.photo-register-url li.add input {
	width: auto;
}
ul.photo-register-url span {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	bottom: 0;
	width: 107px;
	height: 60px;
	border: 1px solid #ddd;
	box-sizing: border-box;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #f5f5f5;
}
div.photo-register {
	position: relative;
	text-align: center;
}
div.photo-register div.mousedrop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
div.mousedrop {
	display: flex;
	padding: 2.4em;
	border: 2px dashed #ccc;
	border-radius: 10px;
	flex-direction: column;
	justify-content: center;
}
div.mousedrop p {
	text-indent: 0;
}
div.mousedrop p:last-child {
	margin-bottom: 0;
}
div.mousedrop strong {
	display: block;
	margin: 0 0 1em 0;
	font-size: 24px;
	font-weight: 600;
}

/**/
#dashboard {
	margin: 20px;
}
#dashboard .container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 0;
	box-sizing: content-box;
}
#dashboard .container aside {
	flex-direction: row;
	width: 49.5%;
	padding: 1em;
	margin: 0.5%;
	border: 1px solid #000;
	box-sizing: border-box;
}
#dashboard .container aside:nth-child(odd) {
	margin-left: 0;
	margin-right: 0.5%;
}
#dashboard .container aside:nth-child(even) {
	margin-left: 0.5%;
	margin-right: 0;
}

.shippingOperation {
	padding-left: 200px;
	width: 400px;
}

/*==================================================================================================================
■ナビゲーション設定
==================================================================================================================*/
/*ヘッダー メインナビ*/
#main-nav {
	margin: 10px 0 0 25px;
	border-bottom: 1px solid #ddd;
}
#main-nav div.nav1 {
	display: flex;
	height: 40px;
	padding-right: 25px;
	margin-bottom: 10px;
	font-size: 16px;
	line-height: 40px;
}
#main-nav div.nav2 {
	font-size: 16px;
}
#main-header a.logo {
	font-size: 24px;
	text-decoration: none;
	color: #000;
	font-weight: 800;
}
#main-header a.logo::first-letter {
	font-size: 120%;
}
#main-nav ul.global-menu {
	display: flex;
	width: 100%;
	height: 41px;
	box-sizing: border-box;
	justify-content: flex-start;
	margin-bottom: -1px;
}
#main-nav ul.sub-menu {
	display: flex;
	width: 250px;
	height: 41px;
	box-sizing: border-box;
	justify-content: flex-end;
	margin-bottom: -1px;
}
#main-nav ul.global-menu li a,
#main-nav ul.sub-menu li a {
	display: block;
	flex-direction: row;
	padding: 0.5em 0.5em 0.3em 0.5em;
	margin: 2px 1.6em 0 0;
	text-decoration: none;
	font-weight: 500;
	color: #333;
	border-bottom: 1px solid #333;
	transition: all 0.3s;
}
#main-nav ul.global-menu li.sku a {
	border-color: #d00;
}
#main-nav ul.global-menu li.stock a {
	border-color: #ea0;
}
#main-nav ul.global-menu li.order a {
	border-color: #8d0;
}
#main-nav ul.global-menu li.purchase a {
	border-color: #09d;
}
#main-nav ul.global-menu li.active a,
#main-nav ul.global-menu li a:hover,
#main-nav ul.sub-menu li a:hover {
	margin-top: 0px;
	border-bottom-width: 3px;
	font-weight: 600;
}

#main-nav ul.search-menu,
#main-nav ul.login-menu {
	display: flex;
	box-sizing: border-box;
	justify-content: flex-end;
}
#main-nav ul.search-menu {
	margin-right: 20px;
}
#main-nav ul.search-menu li,
#main-nav ul.login-menu li {
	flex-direction: row;
	position: relative;
	margin: 0;
	padding: 0 1em;
	list-style: none;
	text-align: center;

	&::before {
		content: "";
		display: block;
		width: 0;
		height: 1em;
		border-right: 1px solid #333;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
	}

	&:first-child::before {
		content: none;
	}
}

/*ヘッダー サブナビ*/
#sub-nav {
	padding: 0.75em 0;
	margin: 0 0 0 1.6em;
	border-bottom: 1px solid #ddd;
}
#sub-nav ul {
	display: flex;
}
#sub-nav ul li {
	margin: 0 1em 0 0;
	font-size: 14px;
	color: #333;
}
#sub-nav ul li a {
	display: inline-block;
	height: 30px;
	padding: 0 0.75em;
	text-decoration: none;
	color: #333;
	background: #eee;
	border-radius: 1.5em;
	line-height: 30px;
}
#sub-nav ul li a:hover {
	color: #fff;
	background: #444;
}

/*フッター ナビ*/
#foot-nav ul.global-menu {
	display: flex;
	box-sizing: border-box;
	justify-content: center;
}
#foot-nav ul.global-menu li {
	flex-direction: row;
	padding: 0.5em 1em 0.2em 1em;
	margin: 0 2px;
	border-right: 1px solid #eee;
}
#foot-nav ul.global-menu li:first-child {
	border-left: 1px solid #eee;
}
#foot-nav ul.global-menu li a {
	color: #eee;
}

#overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.6);
	z-index: 2147483640;
}

/*スクロールバー*/
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
::-webkit-scrollbar-track {
	margin: 0;
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: #ddd;
	border-radius: 10px;
	cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #999;
}

.pagination {
	margin: 1em 0 0 0;
}
.pagination > a {
	margin-right: 0.5em;
	text-decoration: none;
	color: #ddd;
	font-size: 14px;
}
.pagination > a span,
.pagination > a:hover {
	color: #222;
}

.ipp-selector {
	float: right;
	margin-left: 0.5em;
}
.ipp-selector select {
	margin-left: 0.5em;
}

.incorrect {
	background-color: #ffb0b0;
}

div.modalbox div.wrapper-right > .close {
	display: block;
	margin: 0;
	position: absolute;
	height: 25px;
	width: 25px;
	background: #bbb;
	top: -7px;
	right: -7px;
	z-index: 1000;
	cursor: pointer;
	border-radius: 100%;
	line-height: 23px;
	text-align: center;
	color: #fff;
	z-index: 2147483642;
}
div.modalbox div.wrapper-right > .close:hover {
	background: #444;
}
div.modalbox div.wrapper-right > .close::after {
	content: "\f00d";
	font-family: FontAwesome;
}
div.modalbox div.popup-table-box {
    overflow: auto;
    max-height: calc(90vh - 70px);
    box-sizing: border-box;
}
div.modalbox div.popup-table-box table {
	border-right: 1px solid #f9f9f9;
	border-bottom: 1px solid #f9f9f9;
	border-collapse: collapse;
	min-width: 500px;
	position: relative;
	background: #ffffff;
}
div.modalbox div.popup-table-box table th{
	position: sticky;
	top: -1px;
	min-width: 40px;
	padding: 1em 0.5em;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	background-color: #f0f0f0;
	user-select: none;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	z-index: 90;
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
div.modalbox div.popup-table-box table td{
	border-right: 1px solid #eee;
	border-bottom: 1px solid #eee;
}
div.modalbox div.popup-table-box table td {
	padding: 0.5em;
	text-align: left;
	font-size: 14px;
}
.pagination + .submitArea {
	margin-top: 1.6em;
}

div.state-selector {
	margin: 1em 0 1.6em 0;
}
div.state-selector div + div {
	margin-top: 0.5em;
}
div.state-selector label {
	display: inline-block;
	height: 30px;
	padding: 0 0.75em;
	margin: 0 0.5em 0 0;
	border: 1px solid #eee;
	background: #f9f9f9;
	border-radius: 1.5em;
	color: #333;
	text-decoration: none;
	line-height: 30px;
}
div.state-selector label input {
	min-height: initial;
	vertical-align: baseline;
}
a[href="https://developer.yahoo.co.jp/about"] {
	position: absolute;
	left: 0;
	bottom: 0;
}
.sub-col5-title {
	display: block;
	width: calc(100% + 1em);
	border-top: 1px solid #ddd;
	margin: 0.5em 0 -0.75em -0.5em;
	text-align: left;
}
.sub-col5-title span {
	display: inline-block;
	width: 20%;
    padding: 0.75em;
    letter-spacing: -0.025em;
    font-size: 14px;
    word-break: break-all;
    border-left: 1px solid #ddd;
	box-sizing: border-box;
	text-align: center;
}
.sub-col5-title span:first-child {
	border: none;
}

td.sub-col5 span {
	border-left: 1px solid #eee;
	width: 20%;
	box-sizing: border-box;
	text-align: right;
}
td.sub-col5 span:first-child {
	border: none;
}

div.data-table {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
div.data-table table.data-table {
	width: 600px;
}
.address-picker {
	position: absolute;
}
.address-picker ul {
	border: 1px solid gray;
	background-color: white;
	padding: 5px;
	border-radius: 5px;
}
.address-picker li {
	margin: 0px;
	padding: 0.5em;
	cursor: pointer;
}
.address-picker li:hover {
	background-color: lightpink;
}

table.edit-table td span.is-set-inventory {
	padding: 0.1em;
}
table.edit-table td span.is-not-set-inventory {
	padding: 0.1em;
	color: #AAA;
}

/* 受注管理の一括処理メニュー */
.menu-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	
	& > .menu-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		& > .menu-col-6 {
			width: 50%;
		}
		& > .menu-col-12 {
			width: 100%;
		}
	}
}

.shippingMenu {
	margin: 24px 0;
}
.shippingMenuToggle {
	margin: 2px;
	padding: 8px;
	text-align: right;
	background-color: #f0f0f0;

	& > a::after {
		content: "∨";
		padding-left: 4px;
		font-size: 12px;
	}

	& > a.is_active::after {
		content: "∧";
	}
}
.shippingMenuBase {
	display: none;
	flex-direction: column;
	width: 100%;

	&.is_active {
		display: flex;
	}

	& > .row {
		display: flex;
		justify-content: space-between;
		width: 100%;

		& > .col {
			width: 50%;
			margin: 2px;
			padding: 8px;
			background-color: #f0f0f0;

			display: flex;
			flex-wrap: nowrap;
			align-items: center;

			& > div {
				margin: 0 4px;

				& > * + * {
					margin-left: 8px;
				}

				&.input {
					width: 300px;
				}

				&.input > select,input {
					width: 100%;
					margin: 0;
				}

				&:first-child {
					margin-right: auto;
				}
			}
		}
	}

	&::after {
		margin-bottom: 16px;
	}
}
