@import "https://fonts.googleapis.com/earlyaccess/notosansjp.css";
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, p, blockquote, th, td, header, article, aside, nav, footer, hgroup, section, button {
  margin: 0;
  padding: 0;
}

fieldset, img, iframe {
  vertical-align: top;
  border: 0;
}

select, input, textarea, button:not(.react-datepicker__navigation) {
  vertical-align: baseline;
  box-sizing: border-box;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-height: 36px;
  padding: .25em;
  font-family: Noto Sans JP;
  font-size: 100%;
  font-weight: 400;
  line-height: 1;
}

select:focus, input:focus, textarea:focus, button:focus {
  border: 1px solid #aaa;
  outline: 0;
}

input[type="checkbox"], input[type="radio"] {
  min-height: auto;
}

input[type="submit"] {
  color: #17d;
  background: #fff;
  padding: .25em .5em;
  font-size: 80%;
}

input::placeholder {
  color: #d3d3d3;
}

button {
  background: #fff;
  padding: .25em .5em;
}

button:disabled {
  filter: grayscale() contrast(75%);
}

address, strong, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym, iframe {
  border: 0;
}

.clear:after {
  visibility: hidden;
  content: " ";
  clear: both;
  height: 0;
  font-size: 0;
  display: block;
}

html, body {
  font-family: Noto Sans JP;
  font-size: 16px;
  font-weight: 400;
}

body {
  position: relative;
}

body.development {
  background-color: #ff00001a;
}

body.development #main-header a.logo:after {
  content: "SASAGASE(開発環境)";
}

div.loading {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  font-weight: 600;
  position: absolute;
  inset: 0;
}

.flex-box {
  display: flex;
}

.flex-box.between {
  justify-content: space-between;
}

.flex-box.end {
  justify-content: flex-end;
}

.flex-box.wrap {
  flex-wrap: wrap;
}

.flex-box.column {
  flex-direction: column;
}

.baseposition {
  position: relative;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

#contents-wrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;
  display: flex;
}

#main-header {
  flex-shrink: 0;
  margin: 0;
  padding: 0 0 25px;
  display: block;
}

#main-footer {
  box-sizing: border-box;
  color: #eee;
  background: #1b3249;
  flex-shrink: 0;
  width: 100%;
  padding: 20px;
}

#main-container {
  flex-grow: 2;
  display: flex;
  position: relative;
}

#main-container > div.flex-box {
  width: 100%;
  overflow: auto;
}

#main-container > div.flex-box > div.wrapper-left {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 220px;
  max-width: 300px;
  padding: 0;
}

#main-container > div.flex-box > div.wrapper-left::-webkit-scrollbar {
  width: 5px;
}

#main-container > div.flex-box > div.wrapper-right {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 0 25px 25px;
}

#main-container > div.flex-box > div.wrapper-left + div.wrapper-right {
  width: calc(100% - 220px);
}

#main-container > div.flex-box > div.wrapper-left nav, #main-container > div.flex-box > div.wrapper-right article {
  color: #444;
  margin: 0;
  padding: 1em;
  font-weight: 400;
}

#main-container > div.flex-box > div.wrapper-right > h2 {
  margin: 0 0 .5em;
  font-size: 21px;
  font-weight: 600;
}

article h3 {
  margin: 0 0 .5em;
  font-size: 18px;
  font-weight: 600;
}

article h4, article h5, article h6 {
  margin: 0 0 .5em;
  font-size: 16px;
  font-weight: 500;
}

article em {
  font-weight: bold;
}

div.flex-box > div.wrapper-right > article {
  margin: 0 0 25px;
}

#main-container > div.flex-box > div.wrapper-right > article, #main-container div.wrapper-right > div.listings-contents > article {
  background-color: #fff;
  border-radius: 8px 0 0 8px;
  margin: 0 0 25px;
  padding: 25px;
}

#main-container > div.flex-box > div.wrapper-right > article:last-child, #main-container div.wrapper-right > div.listings-contents > article:last-child {
  margin-bottom: 0;
}

#main-container > div.flex-box > div.wrapper-right article section {
  background-color: inherit;
  margin-bottom: 2.1em;
}

#main-container > div.flex-box > div.wrapper-right article section table {
  background-color: inherit;
}

#main-container > div.flex-box > div.wrapper-right article section:last-child {
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

table.data-table {
  width: 100%;
}

table.data-table > tbody > tr > th {
  vertical-align: top;
  width: 200px;
  padding: .5em;
  font-size: 16px;
  font-weight: 500;
}

table.data-table > tbody > tr > td {
  padding: .5em;
}

ul.data-box {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin: 0 0 -1em;
  display: flex;
}

ul.data-box li {
  width: 40%;
  margin: 0 0 1em;
  padding: 0;
}

ul.data-box.edit-item-size_wrap {
  justify-content: flex-start;
}

ul.data-box.edit-item-size_wrap li {
  width: auto;
  margin: 0 1em 1em 0;
}

ul.data-box li input {
  width: 100%;
}

div.edit-table-box {
  width: 100%;
  height: max-content;
  max-height: calc(90vh - 260px);
  margin: 1em 0;
  padding-bottom: 8px;
  overflow: auto;
}

table.edit-table {
  width: 100%;
  margin: 0;
}

table.edit-table, table.edit-table th, table.edit-table td {
  table-layout: fixed;
  text-overflow: ellipsis;
  box-sizing: border-box;
  border-collapse: separate;
  font-weight: 500;
  position: relative;
}

table.edit-table th {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  z-index: 90;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  min-width: 40px;
  padding: 1em .5em;
  font-size: 14px;
  line-height: 1;
  position: sticky;
  top: -1px;
  overflow: hidden;
}

table.edit-table th.text-wrap, table.edit-table td.text-wrap {
  white-space: normal;
}

table.edit-table th div.resizable-handle {
  cursor: col-resize;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

table.edit-table td {
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  min-width: 40px;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  overflow: hidden;
}

table.edit-table table tr:first-child td {
  border-top: 1px solid #eee;
}

table.edit-table tr:nth-child(odd) td {
  background-color: #fee;
}

table.edit-table tr:nth-child(2n) td {
  background-color: #fff;
}

table.edit-table tr.incorrect:nth-child(odd) td, table.edit-table tr.incorrect:nth-child(2n) td {
  background-color: inherit;
}

table.edit-table tr.set-item td {
  background-color: #e0e0ff;
}

table.edit-table th[width="10"], table.edit-table td[width="10"] {
  width: auto;
}

table.edit-table td.center {
  text-align: center;
}

table.edit-table td.right {
  text-align: right;
}

table.edit-table th.noresize, table.edit-table td.noresize {
  text-align: center;
}

table.edit-table th.noresize:first-child {
  z-index: 1001;
  position: sticky;
  left: 0;
}

table.edit-table td.noresize:first-child {
  z-index: 1000;
  position: sticky;
  left: 0;
}

table.edit-table .incorrect td.noresize:first-child {
  background: inherit;
}

table.edit-table th:last-child, table.edit-table td:last-child {
  border-right: none;
}

.ui-resizable-e {
  width: 5px;
  right: 0;
}

table.edit-table th.disabled, table.edit-table td.disabled {
  background-color: #fafafa;
}

table.edit-table th span {
  white-space: nowrap;
}

table.edit-table th i {
  cursor: pointer;
}

table.edit-table td span {
  letter-spacing: -.025em;
  word-break: break-all;
  padding: .75em;
  font-size: 14px;
  display: inline-block;
}

table.edit-table td.data span, table.edit-table td a {
  color: #17d;
  cursor: pointer;
  text-decoration: none;
}

table.edit-table td > select, table.edit-table td > textarea, table.edit-table td > input {
  border: 1px solid #0000;
  border-radius: 0;
  width: 100%;
}

table.edit-table td > select.right, table.edit-table td > textarea.right, table.edit-table td > input.right {
  text-align: right;
}

table.edit-table td > textarea {
  resize: vertical;
}

table.edit-table td > input[type="checkbox"] {
  width: min-content;
  margin: 0 auto;
  display: block;
}

table.edit-table td > select:active, table.edit-table td > select:hover, table.edit-table td > input:active, table.edit-table td > input:hover {
  border-color: #999;
}

table.edit-table td > button.plus-button, table.edit-table td > button.delete-button {
  border-color: #999;
  width: 1rem;
  height: 1rem;
  min-height: auto;
  padding: 0;
}

table.edit-table td > button.plus-button:after {
  content: "➕";
}

table.edit-table td > button.delete-button:after {
  content: "➖";
}

table.edit-table td > .edit-select {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: .25em;
}

@media only screen and (height <= 600px) {
  div.edit-table-box {
    max-height: max-content;
  }
}

table.edit-table.sub-edit-table {
  width: auto;
}

table.edit-table.sub-edit-table input[type="text"] {
  border: 1px solid #ddd;
  width: 10em;
}

table.edit-table.sub-edit-table th.group-name {
  text-align: left;
  padding-left: 1em;
}

table.edit-table.sub-edit-table th.location-name {
  text-align: left;
  padding-left: 2em;
}

table.edit-table.sub-edit-table p.error {
  color: #d00;
  padding: .5em;
}

.width50 {
  width: 50px;
}

.width100 {
  width: 100px;
}

.width200 {
  width: 200px;
}

.width300 {
  width: 300px;
}

.width400 {
  width: 400px;
}

.width500 {
  width: 500px;
}

.width600 {
  width: 600px;
}

.width700 {
  width: 700px;
}

.min-width10 {
  min-width: 10px;
}

.min-width50 {
  min-width: 50px;
}

.min-width100 {
  min-width: 100px;
}

.min-width200 {
  min-width: 200px;
}

.min-width300 {
  min-width: 300px;
}

.min-width400 {
  min-width: 400px;
}

.min-width500 {
  min-width: 500px;
}

.min-width600 {
  min-width: 600px;
}

.min-width700 {
  min-width: 700px;
}

table.data-table tr.required th:before, article h2.required:before, article h3.required:before, article h4.required:before, article h5.required:before, article h6.required:before {
  content: "*";
  color: #c00;
}

table.data-table tr.commend th:before, article h2.commend:before, article h3.commend:before, article h4.commend:before, article h5.commend:before, article h5.commend:before {
  content: "*";
  color: #666;
}

article p {
  margin: 0 0 1.6em;
}

p.supplement {
  color: #888;
  margin: .3em 0;
  font-size: 13px;
  display: block;
}

article span.inline {
  display: inline;
}

p.supplement + table, p.supplement + ul {
  margin-top: 1em;
}

article h3 + p.supplement, article h4 + p.supplement, article h5 + p.supplement, article h6 + p.supplement {
  margin-top: -.5em;
}

p.supplement + h3, p.supplement + h4, p.supplement + h5, p.supplement + h6 {
  margin-top: .75em;
}

p.supplement + p.supplement {
  margin-top: -.6em;
}

.point span, span.point, span.modaltoggle, span.onetoggle {
  color: #0654ba;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.mb1 span, span.mb1 {
  margin-bottom: 1em;
}

.fs12 span, span.fs12 {
  font-size: 12px;
}

.fs14 span, span.fs14 {
  font-size: 14px;
}

.fs16 span, span.fs16 {
  font-size: 16px;
}

.fs18 span, span.fs18 {
  font-size: 18px;
}

.fs20 span, span.fs20 {
  font-size: 20px;
}

article ul, article ul li {
  margin: 0 0 1.6em;
}

article ul.child-mb05 li {
  margin: 0 0 .5em;
}

article ul.child-mb1 li {
  margin: 0 0 1em;
}

article ul.mb0 {
  margin-bottom: 0;
}

article ul.mb05 {
  margin-bottom: .5em;
}

article ul.mb1 {
  margin-bottom: 1em;
}

article ul:last-child {
  margin-bottom: 0;
}

article ul.inline-blok li {
  margin: 0 1em .5em 0;
  display: inline-block;
}

article ul.inline-blok li.block, article ul li.block {
  display: block;
}

article ul.onetoggle, article ul.toggle {
  margin: .5em 0 0;
  display: none;
}

.edit-item-makertag-list, .edit-item-itemtag-list {
  margin: 0 0 .5em;
  padding-left: .5em;
}

.edit-item-makertag-list li, .edit-item-itemtag-list li {
  -webkit-user-select: none;
  user-select: none;
}

li.edit-item-makertag_wrap, li.edit-item-itemtag_wrap, li.edit-item-category_wrap {
  opacity: 0;
  display: none;
}

li.size-packing ul {
  margin-bottom: 1.6em;
}

input {
  display: inline-block;
}

table.data-table input[type="text"] {
  width: 100%;
}

.edit-sku-number_wrap input[type="text"], .edit-item-number_wrap input[type="text"] {
  max-width: 200px;
}

.edit-item-jancode_wrap input[type="text"] {
  max-width: 300px;
}

.edit-item-price_wrap input[type="text"], .edit-item-size_wrap input[type="text"] {
  max-width: 100px;
}

input.tag-plus {
  max-width: 200px;
  margin-right: 10px;
}

textarea.public-key {
  width: 80em;
  height: 8em;
  font-family: Menlo, Monaco, Courier New, Consolas, monospace;
  font-size: small;
}

table.data-table button:not(.react-datepicker__navigation) {
  cursor: pointer;
  transition: all .5s;
  box-shadow: 1px 1px 5px #0000001a;
}

div.submit-area {
  margin: 1em 0;
  padding: 0 0 0 1em;
}

div.submit-area input, div.submit-area button {
  cursor: pointer;
  border-radius: 5px;
  margin: 0 1em 0 0;
  padding: .75em 1.6em;
  font-size: 18px;
  transition: all .5s;
  display: inline-block;
  box-shadow: 1px 1px 5px #0000001a;
}

div.submit-area input.register, div.submit-area button.register {
  color: #fff;
  background-color: #17d;
  border: none;
}

div.submit-area input.save {
  color: #0654ba;
  background-color: #fff;
  border: none;
}

div.submit-area input.register:hover, div.submit-area button.register:hover, div.submit-area input.save:hover {
  color: #fff;
  background-color: #1b3249;
}

input.edit-sku-number + span {
  vertical-align: middle;
  color: #6d3;
  height: 36px;
  margin: 0 0 0 .5em;
  line-height: 36px;
}

div.datepicker {
  display: inline-block;
}

textarea {
  width: 100%;
}

textarea.edit-bulk-id {
  max-width: 200px;
  margin: 0 0 .5em;
  display: block;
}

form.formbox div.flex-box > div {
  margin: 0 10px 10px 0;
}

form.formbox div.size10rem {
  width: 10rem;
}

form.formbox div.size11rem {
  width: 11rem;
}

form.formbox div.size12rem {
  width: 12rem;
}

form.formbox div.size13rem {
  width: 13rem;
}

form.formbox div.size14rem {
  width: 14rem;
}

form.formbox div.size15rem {
  width: 15rem;
}

form.formbox div.size25rem {
  width: 25rem;
}

form.formbox div.submitArea {
  margin: 0;
}

form.formbox div.inputArea input, form.formbox div.inputArea button {
  width: 100%;
}

form.formbox div.inputArea td {
  padding: 2px;
}

form.formbox div.inputArea td button {
  width: auto;
}

form.formbox div.inputArea td button, form.formbox div.inputArea td input, form.formbox div.inputArea td select {
  margin: 0 10px 0 0;
}

div.submitArea {
  margin: 1em 0;
}

div.submitArea input, div.submitArea button {
  margin: 0 10px 0 0;
}

form.formbox button {
  text-align: left;
  position: relative;
}

form.formbox button.toggle:after {
  content: "";
  letter-spacing: 0;
  float: right;
  margin-right: .25em;
  font-family: FontAwesome;
}

form.formbox button.toggle.open {
  border: 1px solid #aaa;
}

form.formbox ul.toggle {
  box-sizing: border-box;
  z-index: 100;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 37px;
  left: 0;
}

form.formbox ul.toggle li {
  border-bottom: 1px dotted #eee;
  margin: 0;
  font-size: 14px;
}

form.formbox ul.toggle li:last-child {
  border: none;
}

form.formbox ul.toggle li span, form.formbox ul.toggle li label {
  cursor: pointer;
  color: #666;
  padding: 5px;
  display: block;
}

form.formbox ul.toggle li label:hover {
  color: #fff;
  background-color: #17d;
}

form.formbox ul.toggle li input {
  border: 1px solid #eee;
  width: 100%;
}

form.formbox ul.toggle li label input {
  width: 1em;
  min-height: auto;
  display: none;
}

form.formbox div.item-tag > div.level-2, form.formbox div.item-tag > div.level-3, form.formbox div.item-tag > div.level-4 {
  display: none;
}

.bl_password {
  position: relative;
}

.bl_password input {
  padding-right: 40px;
}

.bl_password .el_showPassword:before {
  content: "";
  background-image: url("eye-slash-fill.b5a4aeb0.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.bl_password .el_showPassword.active:before {
  background-image: url("eye-fill.16b9e1ef.svg");
}

p.error {
  color: #d00;
  margin: 4px 0;
  font-size: 13px;
}

div.modalbox {
  box-sizing: border-box;
  z-index: 2147483641;
  background: #fff;
  border-radius: 5px;
  width: max-content;
  height: max-content;
  margin: auto;
  position: fixed;
  inset: 0;
  box-shadow: 1px 1px 8px #0000004d;
}

div.modalbox > div {
  box-sizing: border-box;
  width: max-content;
  max-width: 90vw;
  height: max-content;
  max-height: 90vh;
  padding: 35px;
  overflow: auto;
}

div.modalbox div.wrapper {
  margin: 0 0 2.4em;
}

div.modalbox div.wrapper:last-child {
  margin: 0;
}

div.modalbox input[type="submit"] {
  position: initial;
  width: auto;
  height: auto;
}

div.tagbox {
  margin: 0;
  padding: 0 0 .5em;
}

div.tagbox input {
  margin: 0 3px 5px 0;
}

div.tagbox input.on {
  color: #fff;
  background-color: #333;
}

article div.onetoggle, article div.toggle {
  display: none;
}

div.sizebox {
  margin: 0;
}

div.sizebox input {
  margin: 0 3px 5px 0;
}

div.sizebox input.size-plus {
  margin-right: 10px;
}

div.sizebox input.size-00, div.sizebox input.on {
  color: #fff;
  background-color: #333;
}

div.expectation-taggroup {
  margin-top: 1.6em;
  display: none;
}

div.expectation-taggroup.visible {
  display: block;
}

div.listings-wrapper {
  border: 1px solid #ddd;
  width: 100%;
  min-height: 300px;
  display: flex;
  overflow: hidden;
}

div.listings-wrapper div.wrapper-left {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 50%;
  height: 100%;
}

div.listings-wrapper div.wrapper-right {
  box-sizing: border-box;
  background: #f5f5f5;
  border-left: 1px solid #ddd;
  width: 50%;
  height: 100%;
  padding-left: 3px;
}

div.listings-contents {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;
}

div.listings-wrapper div.wrapper-right div.listings-contents {
  background: #f5f5f5;
  padding: 1em 0 1em 1em;
}

div.tag-selector {
  background: #eee;
  border: 2px solid #ddd;
  align-content: stretch;
  display: flex;
}

div.tag-selector div.wrapper-left {
  background: #fff;
  border-right: 2px solid #fff;
  min-width: 220px;
}

div.tag-selector div.wrapper-center {
  background: #fff;
  border-right: 1px solid #eee;
  flex-direction: column;
  justify-content: center;
  width: 50px;
  margin-left: 3px;
  display: flex;
}

div.tag-selector div.wrapper-right {
  background: #fff;
  flex-direction: column;
  flex-grow: 1;
  align-content: stretch;
  max-width: 100%;
  display: flex;
}

div.tag-selector div.wrapper-right div.selected {
  border-bottom: 1px solid #eee;
  flex-grow: 2;
  padding: .5em;
}

div.tag-selector div.wrapper-right div.recommend {
  flex-grow: 1;
  padding: .5em;
}

div.tag-selector div.tag-tree {
  height: 100%;
}

div.selected ul {
  display: flex;
}

div.selected ul li {
  margin: 0;
  display: flex;
}

div.selected ul li span {
  background: #f5f5f5;
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 0 5px 5px 0;
  padding: .5em;
  display: inline-block;
}

div.scrollbox {
  box-sizing: border-box;
  width: 100%;
  height: 0;
  padding-left: 25px;
  padding-right: 5px;
  overflow-y: auto;
}

div.leftnav ul {
  border-bottom: 1px solid #ddd;
  padding-bottom: .6em;
}

div.leftnav ul li {
  margin: 0 0 1em;
  font-size: 14px;
}

div.tag-selector div.tag-tree {
  padding: 0;
}

ul.tag-tree.edit-on li {
  padding-left: 1.5em;
  padding-right: 1.5em;
  position: relative;
}

ul.tag-tree.edit-on li:after {
  content: "";
  letter-spacing: 0;
  text-indent: 0;
  height: 1em;
  margin: auto;
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  bottom: 0;
  right: .5em;
}

span.arrow:after {
  content: "";
  margin-right: .25em;
  font-family: FontAwesome;
}

span.arrow.open:after {
  content: "";
  margin-right: .25em;
  font-family: FontAwesome;
}

ul.edit-on span.delete:after {
  content: "";
  letter-spacing: 0;
  color: #c00;
  text-indent: 0;
  height: 1em;
  margin: auto;
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  bottom: 0;
  left: .5em;
}

div.tag-tree div.edit {
  padding: .5em;
}

div.tag-tree div.edit ul.edit-button span {
  text-align: center;
  background: #eee;
  border: 1px solid #999;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  overflow: hidden;
}

div.tag-tree #tag-registration {
  width: 100%;
}

div.tag-tree select.tag-domain {
  background: none;
  border: none;
  width: 100%;
}

div.tag-tree select.tag-domain:before {
  width: 2px;
  height: 100%;
  display: block;
}

ul.tag-tree {
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  justify-content: center;
  width: 100%;
}

ul.tag-tree li {
  box-sizing: border-box;
  color: #444;
  border-bottom: 1px solid #eee;
  width: 100%;
  margin: 0;
  padding: .5em;
  font-size: 14px;
  display: block;
}

ul.tag-tree li.tag-domain {
  border-top: 1px solid #eee;
}

ul.tag-tree li.into-top {
  border-top: 2px solid red;
  padding-top: 5px;
}

ul.tag-tree li.into-bottom {
  border-bottom: 2px solid red;
  padding-bottom: 6px;
}

ul.tag-tree li.into-child {
  border: 2px solid red;
  padding-top: 5px;
  padding-bottom: 6px;
}

ul.tag-tree li.into-child.tag-domain, ul.tag-tree li.into-top.tag-domain {
  padding-top: 6px;
}

ul.photo-register-box {
  border-left: 1px solid #eee;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 1em;
  display: flex;
}

ul.photo-register-box li {
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  width: 10%;
  height: 0;
  margin: 0 0 -1px;
  padding-top: 10%;
  position: relative;
}

ul.photo-register-box li > input[type="submit"] {
  box-sizing: border-box;
  color: #ccc;
  background: #fff;
  border: none;
  border-radius: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: 30px;
  position: absolute;
  inset: 0;
}

ul.photo-register-box li > input[type="submit"]:hover {
  color: #333;
  background: #f5f5f5;
}

ul.photo-register-box li > input[value="変更"] {
  color: #0000;
  background: none;
}

ul.photo-register-box li > input[value="変更"]:hover {
  color: #fff;
  background: #000000b3;
  font-size: 20px;
}

ul.photo-register-box.instructions-photo li {
  margin-bottom: 45px;
}

ul.photo-register-box.instructions-photo select {
  width: 100%;
  margin-top: 3px;
}

ul.photo-register-url {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: .5em;
  display: flex;
}

ul.photo-register-url li {
  box-sizing: border-box;
  color: #777;
  width: 49%;
  height: 60px;
  margin: 0 0 1em;
  padding: 0 0 0 110px;
  font-size: 14px;
  line-height: 1;
  position: relative;
}

ul.photo-register-url li.add {
  padding: 0;
}

ul.photo-register-url input {
  width: 100%;
  margin-top: .5em;
  display: block;
}

ul.photo-register-url li.add input {
  width: auto;
}

ul.photo-register-url span {
  box-sizing: border-box;
  background-color: #f5f5f5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #ddd;
  width: 107px;
  height: 60px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

div.photo-register {
  text-align: center;
  position: relative;
}

div.photo-register div.mousedrop {
  position: absolute;
  inset: 0;
}

div.mousedrop {
  border: 2px dashed #ccc;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  padding: 2.4em;
  display: flex;
}

div.mousedrop p {
  text-indent: 0;
}

div.mousedrop p:last-child {
  margin-bottom: 0;
}

div.mousedrop strong {
  margin: 0 0 1em;
  font-size: 24px;
  font-weight: 600;
  display: block;
}

#dashboard {
  margin: 20px;
}

#dashboard .container {
  box-sizing: content-box;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  display: flex;
}

#dashboard .container aside {
  box-sizing: border-box;
  border: 1px solid #000;
  flex-direction: row;
  width: 49.5%;
  margin: .5%;
  padding: 1em;
}

#dashboard .container aside:nth-child(odd) {
  margin-left: 0;
  margin-right: .5%;
}

#dashboard .container aside:nth-child(2n) {
  margin-left: .5%;
  margin-right: 0;
}

.shippingOperation {
  width: 400px;
  padding-left: 200px;
}

#main-nav {
  border-bottom: 1px solid #ddd;
  margin: 10px 0 0 25px;
}

#main-nav div.nav1 {
  height: 40px;
  margin-bottom: 10px;
  padding-right: 25px;
  font-size: 16px;
  line-height: 40px;
  display: flex;
}

#main-nav div.nav2 {
  font-size: 16px;
}

#main-header a.logo {
  color: #000;
  font-size: 24px;
  font-weight: 800;
  text-decoration: none;
}

#main-header a.logo:first-letter {
  font-size: 120%;
}

#main-nav ul.global-menu {
  box-sizing: border-box;
  justify-content: flex-start;
  width: 100%;
  height: 41px;
  margin-bottom: -1px;
  display: flex;
}

#main-nav ul.sub-menu {
  box-sizing: border-box;
  justify-content: flex-end;
  width: 250px;
  height: 41px;
  margin-bottom: -1px;
  display: flex;
}

#main-nav ul.global-menu li a, #main-nav ul.sub-menu li a {
  color: #333;
  border-bottom: 1px solid #333;
  flex-direction: row;
  margin: 2px 1.6em 0 0;
  padding: .5em .5em .3em;
  font-weight: 500;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

#main-nav ul.global-menu li.sku a {
  border-color: #d00;
}

#main-nav ul.global-menu li.stock a {
  border-color: #ea0;
}

#main-nav ul.global-menu li.order a {
  border-color: #8d0;
}

#main-nav ul.global-menu li.purchase a {
  border-color: #09d;
}

#main-nav ul.global-menu li.active a, #main-nav ul.global-menu li a:hover, #main-nav ul.sub-menu li a:hover {
  border-bottom-width: 3px;
  margin-top: 0;
  font-weight: 600;
}

#main-nav ul.search-menu, #main-nav ul.login-menu {
  box-sizing: border-box;
  justify-content: flex-end;
  display: flex;
}

#main-nav ul.search-menu {
  margin-right: 20px;
}

#main-nav ul.search-menu li, #main-nav ul.login-menu li {
  text-align: center;
  flex-direction: row;
  margin: 0;
  padding: 0 1em;
  list-style: none;
  position: relative;
}

#main-nav ul.search-menu li:before, #main-nav ul.login-menu li:before {
  content: "";
  border-right: 1px solid #333;
  width: 0;
  height: 1em;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

#main-nav ul.search-menu li:first-child:before, #main-nav ul.login-menu li:first-child:before {
  content: none;
}

#sub-nav {
  border-bottom: 1px solid #ddd;
  margin: 0 0 0 1.6em;
  padding: .75em 0;
}

#sub-nav ul {
  display: flex;
}

#sub-nav ul li {
  color: #333;
  margin: 0 1em 0 0;
  font-size: 14px;
}

#sub-nav ul li a {
  color: #333;
  background: #eee;
  border-radius: 1.5em;
  height: 30px;
  padding: 0 .75em;
  line-height: 30px;
  text-decoration: none;
  display: inline-block;
}

#sub-nav ul li a:hover {
  color: #fff;
  background: #444;
}

#foot-nav ul.global-menu {
  box-sizing: border-box;
  justify-content: center;
  display: flex;
}

#foot-nav ul.global-menu li {
  border-right: 1px solid #eee;
  flex-direction: row;
  margin: 0 2px;
  padding: .5em 1em .2em;
}

#foot-nav ul.global-menu li:first-child {
  border-left: 1px solid #eee;
}

#foot-nav ul.global-menu li a {
  color: #eee;
}

#overlay {
  z-index: 2147483640;
  background: #0009;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #0000;
  margin: 0;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #ddd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.pagination {
  margin: 1em 0 0;
}

.pagination > a {
  color: #ddd;
  margin-right: .5em;
  font-size: 14px;
  text-decoration: none;
}

.pagination > a span, .pagination > a:hover {
  color: #222;
}

.ipp-selector {
  float: right;
  margin-left: .5em;
}

.ipp-selector select {
  margin-left: .5em;
}

.incorrect {
  background-color: #ffb0b0;
}

div.modalbox div.wrapper-right > .close {
  z-index: 1000;
  cursor: pointer;
  text-align: center;
  color: #fff;
  z-index: 2147483642;
  background: #bbb;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  margin: 0;
  line-height: 23px;
  display: block;
  position: absolute;
  top: -7px;
  right: -7px;
}

div.modalbox div.wrapper-right > .close:hover {
  background: #444;
}

div.modalbox div.wrapper-right > .close:after {
  content: "";
  font-family: FontAwesome;
}

div.modalbox div.popup-table-box {
  box-sizing: border-box;
  max-height: calc(90vh - 70px);
  overflow: auto;
}

div.modalbox div.popup-table-box table {
  border-collapse: collapse;
  background: #fff;
  border-bottom: 1px solid #f9f9f9;
  border-right: 1px solid #f9f9f9;
  min-width: 500px;
  position: relative;
}

div.modalbox div.popup-table-box table th {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  z-index: 90;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  min-width: 40px;
  padding: 1em .5em;
  font-size: 14px;
  line-height: 1;
  position: sticky;
  top: -1px;
  overflow: hidden;
}

div.modalbox div.popup-table-box table td {
  text-align: left;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  padding: .5em;
  font-size: 14px;
}

.pagination + .submitArea {
  margin-top: 1.6em;
}

div.state-selector {
  margin: 1em 0 1.6em;
}

div.state-selector div + div {
  margin-top: .5em;
}

div.state-selector label {
  color: #333;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 1.5em;
  height: 30px;
  margin: 0 .5em 0 0;
  padding: 0 .75em;
  line-height: 30px;
  text-decoration: none;
  display: inline-block;
}

div.state-selector label input {
  min-height: initial;
  vertical-align: baseline;
}

a[href="https://developer.yahoo.co.jp/about"] {
  position: absolute;
  bottom: 0;
  left: 0;
}

.sub-col5-title {
  text-align: left;
  border-top: 1px solid #ddd;
  width: calc(100% + 1em);
  margin: .5em 0 -.75em -.5em;
  display: block;
}

.sub-col5-title span {
  letter-spacing: -.025em;
  word-break: break-all;
  box-sizing: border-box;
  text-align: center;
  border-left: 1px solid #ddd;
  width: 20%;
  padding: .75em;
  font-size: 14px;
  display: inline-block;
}

.sub-col5-title span:first-child {
  border: none;
}

td.sub-col5 span {
  box-sizing: border-box;
  text-align: right;
  border-left: 1px solid #eee;
  width: 20%;
}

td.sub-col5 span:first-child {
  border: none;
}

div.data-table {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

div.data-table table.data-table {
  width: 600px;
}

.address-picker {
  position: absolute;
}

.address-picker ul {
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
}

.address-picker li {
  cursor: pointer;
  margin: 0;
  padding: .5em;
}

.address-picker li:hover {
  background-color: #ffb6c1;
}

table.edit-table td span.is-set-inventory {
  padding: .1em;
}

table.edit-table td span.is-not-set-inventory {
  color: #aaa;
  padding: .1em;
}

.menu-container {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.menu-container > .menu-row {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.menu-container > .menu-row > .menu-col-6 {
  width: 50%;
}

.menu-container > .menu-row > .menu-col-12 {
  width: 100%;
}

.shippingMenu {
  margin: 24px 0;
}

.shippingMenuToggle {
  text-align: right;
  background-color: #f0f0f0;
  margin: 2px;
  padding: 8px;
}

.shippingMenuToggle > a:after {
  content: "∨";
  padding-left: 4px;
  font-size: 12px;
}

.shippingMenuToggle > a.is_active:after {
  content: "∧";
}

.shippingMenuBase {
  flex-direction: column;
  width: 100%;
  display: none;
}

.shippingMenuBase.is_active {
  display: flex;
}

.shippingMenuBase > .row {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.shippingMenuBase > .row > .col {
  background-color: #f0f0f0;
  flex-wrap: nowrap;
  align-items: center;
  width: 50%;
  margin: 2px;
  padding: 8px;
  display: flex;
}

.shippingMenuBase > .row > .col > div {
  margin: 0 4px;
}

.shippingMenuBase > .row > .col > div > * + * {
  margin-left: 8px;
}

.shippingMenuBase > .row > .col > div.input {
  width: 300px;
}

.shippingMenuBase > .row > .col > div.input > select, .shippingMenuBase > .row > .col > div input {
  width: 100%;
  margin: 0;
}

.shippingMenuBase > .row > .col > div:first-child {
  margin-right: auto;
}

.shippingMenuBase:after {
  margin-bottom: 16px;
}

/*# sourceMappingURL=index.a81080a6.css.map */
